<template>
    <div class="adjtFooter">
        <div class="content">
            <div class="left">
                <div class="logo">交通运输</div>
                <div class="line"></div>
                <div class="logo-mini">数字化人才发展平台</div>
            </div>
            <div class="right">
                <span>共享教育资源</span><span style="margin-left:32px;">传承人类文明</span>
            </div>
        </div>
        <saas-nsr-footer :bgColor="'#172A75'" />
    </div>
</template>
<script>
export default {
    name:'sdjtFooter',
    data(){
        return{

        }
    }
}
</script>
<style lang="stylus" scoped>
.adjtFooter{
    width 100%
    height 240px
    background url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/sdjt/foot-bg@2x.png') no-repeat center;
    background-size:130% 100%; 
    .content{
        width 1200px
        margin 0 auto 
        box-sizing border-box
        // padding 0 228px
        height 100%;
        display flex 
        justify-content space-between
        align-items:center
        .left{
            display :flex;
            justify-content:space-between;
            align-items:center
            .logo{
                font-size: 36px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                // line-height: 50px;
            }
            .line{
                width: 1px;
                height: 37px;
                background: #FFFFFF;
                margin: 0 16px;
            }
            .logo-mini{
                font-size: 24px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                // line-height: 50px;
            }
        }   
        .right{
            span{
                font-size: 48px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 67px;
            }
        } 
    }

}
</style>