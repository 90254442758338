<template>
  <div class="footer footer_btn_bg">
    <!-- eslint-disable -->
    <div class="footers-con">
      <div class="infor">
        <div v-for="(item, index) in data.setting" class="one-item" v-if="item.content && item.content.length > 0">
          <h3>{{ item.title }}</h3>
          <ul>  
            <li v-for="(element, i) in item.content">
              <span v-if="element.type == '3'">{{element.label}}:  {{element.value}}</span>
              <span v-if="element.type == '1'" style="cursor: pointer;">{{ element.label }}</span>
              <div class="pop-box" v-if="element.type == '1'">
                <i class="el-icon-caret-left"> </i>
                 <img :src="element.value" alt="" />
              </div> 
              <router-link :to= "element.value" v-if="element.type == '2' && element.value.includes('wwww') || !element.type && element.value.includes('wwww')">{{ element.label }}</router-link>
              <a :href="element.value" v-if="element.type == '2' && !element.value.includes('wwww') || !element.type && !element.value.includes('wwww')">{{ element.label }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div  class="rights">
        {{data.setting[data.setting.length-1].rights}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    console.log("setttingData--------", this.data);
    return {};
  },
};
</script>

<style lang="less">
.footer_btn_bg{
  background-color: #202735;
}
.footer {
  margin: 0 auto;
  color: #fff;
  .infor {
    box-sizing: border-box;
    clear: both;
    display: block;
  }
  .footers-con {
    width: 1200px;
    padding: 20px 0px;
    margin: 0 auto;
    .infor{
      display: flex;
      padding:0px 20px;
      .one-item {
        display: inline-block;
        margin-right: 120px;
        max-width:350px;
        h3 {
          font-size: 18px;
          font-weight: 500;
          color: #ffffff;
          line-height: 25px;
          margin-bottom: 24px;
        }
        ul {
          li {
            position: relative;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #fff;
            margin-bottom: 13px;
            opacity: 0.6;
            a{
              color:#fff;
            }
            .pop-box{
              position:absolute;
              opacity: 1;
              display: none;
              padding:10px;
              left:110%;
              top:-30px;
              i{
                color: #fff;
                left: -5%;
                margin-left: -17x;
                margin-top: -10px;
                position: absolute;
                width: 20px;
                font-size: 25px;
                top: 40px;
              }
              img{
                width:100px;
              }
            }
            &:hover{
              // cursor: pointer;
              .pop-box{
                display: block;
              }
            }
          }
        }
      }
    }
    .rights{
      border-top:1px solid#3B4049;
      height:56px;
      line-height: 56px;
      text-align: center;
      color: #fff;
      opacity: 0.6;
    }
  }
}
</style>
