import { render, staticRenderFns } from "./sdjz2Footer.vue?vue&type=template&id=54d9dfdf&scoped=true"
import script from "./sdjz2Footer.vue?vue&type=script&lang=js"
export * from "./sdjz2Footer.vue?vue&type=script&lang=js"
import style0 from "./sdjz2Footer.vue?vue&type=style&index=0&id=54d9dfdf&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54d9dfdf",
  null
  
)

export default component.exports