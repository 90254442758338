import { render, staticRenderFns } from "./anquanFooter.vue?vue&type=template&id=bef3d062&scoped=true"
import script from "./anquanFooter.vue?vue&type=script&lang=js"
export * from "./anquanFooter.vue?vue&type=script&lang=js"
import style0 from "./anquanFooter.vue?vue&type=style&index=0&id=bef3d062&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bef3d062",
  null
  
)

export default component.exports