<template>
    <div class="anquanFooter">
        <div class="content">
            <div class="left">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/anquan/anquan_footer_logo.png" class="logo" alt="">
            </div>
            <div class="right">
                <p class="contact">联系我们 Contact Us</p>
                <p class="text">主办单位：应急管理部宣传教育中心</p>
                <p class="text">协办单位：北京环球之家文化传媒有限公司</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'anquanFooter',
    data(){
        return{

        }
    }
}
</script>
<style lang="stylus" scoped>
.anquanFooter{
    width 100%
    // height 264px
    background #F8F8F8
    .content{
        width 1200px
        margin 0 auto 
        box-sizing border-box
        padding 0 228px
        display flex 
        justify-content space-between
        align-items:center
        .left{
            padding 32px 0
            .logo{
                width 341px
            }
        }   
        .right{
            .contact{
                font-size: 24px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 24px;
                margin-bottom 32px
            }
            .text{
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 32px;
            }
        } 
    }

}
</style>