<template>
    <div class="yingji-footer">
      <div class="footer-content">
        <div class="footer-l">
          <img src="../asset/images/footer-logo@2x.png" alt="">
          <div class="friend-link">
            <span>友情链接</span>
            <el-select v-model="value" placeholder="请选择" size="small">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <span class="friend-button" @click="openLink">前往</span>
          </div>
        </div>
        <div class="footer-c">
          <p>主办单位：应急管理部宣传教育中心</p>
          <p>承办单位：北京伟东国合教育科技有限公司</p>
          <p v-html="postcode"></p>
          <p v-html="address"></p>
          <p class="footer-c-bot">备 案 号：京ICP备10026535号-28</p>
        </div>
        <div class="footer-r">
          <img src="../asset/images/qrcode.jpg" alt="">
          <span>扫码查看手机版</span>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      postcode: '邮&#12288;&#12288;编： 100098',
      address: '地&#12288;&#12288;址： 北京市海淀区学院南路12号-北京师范大学京师科技大厦A座13层',
      options: [{
        value: 'https://www.mem.gov.cn/',
        label: '国家应急管理部'
      }, {
        value: 'http://www.china-safety.org/',
        label: '国家应急管理宣教网'
      }, {
        value: 'http://www.ndrcc.org.cn/',
        label: '国家减灾网'
      }, {
        value: 'http://www.china-sem.org.cn/',
        label: '中国应急管理学会'
      }, {
        value: 'http://www.ccsr.cn/',
        label: '应急管理部研究中心'
      },{
        value: 'http://www.chinajjjy.org.cn/',
        label: '应急管理部紧急救援促进中心'
      },{
        value: 'http://www.nios.com.cn/',
        label: '应急管理部信息研究院'
      },{
        value: '1. https://www.wdecloud.com/',
        label: '伟东云教育集团'
      }],
      value: ''
    }
  },
  methods: {
    openLink() {
      window.open(this.value,'_blank')
    }
  }
}
</script>
<style lang="stylus" scoped>
  .yingji-footer {
    height: 273px;
    background: #333333;
    border-bottom: 42px solid #292929;
    box-sizing: border-box;
    .footer-content {
      font-size: 14px;
      color: #808080;
      width: 1200px;
      margin: 0 auto;
      height: 100%;
      display: flex;
      align-items: center;
      padding-top: 10px;
      .footer-l {
        font-size: 14px;
        color: #979797;
        img {
          width: 299px;
          height: 45px;
        }
        .friend-link {
          margin-top: 33px;
          /deep/.el-select {
            width: 169px;
            height: 34px;
            margin: 0 13px;
          }
          .friend-button {
            display: inline-block;
            width: 64px;
            height: 33px;
            line-height: 33px;
            background: #D02E3D;
            border-radius: 3px;
            color: #fff;
            font-size: 14px;
            text-align: center;
            cursor: pointer;
          }
        }
      }
      .footer-c {
        font-size: 14px;
        color: #E8E8E8;
        margin: 0 176px 0 160px;
        line-height: 28px;
        .footer-c-bot {
          margin-top: 28px;
        }
      }
      .footer-r {
        display: flex;
        flex-direction: column;
        font-size:16px;
        align-items: center;
        color: #979797;
        img {
          width: 89px;
          height: 89px;
          margin-bottom: 16px;
        }
      }
    }
  }
</style>
