<template>
 <div class='junminFooter'>
    <div class="junminFooter-top">
        <div class="junminFooter-top-main clearfix">
            <div class="junminFooter-top-main-li" v-for="(item,index) in typeList" :key="index">
                <div class="junminFooter-top-main-li-name" v-if="!item.childItem" @click="selectClick($event,item)"><p class="circular clearfix"><span></span></p>{{item.name}}</div>
                <el-select v-if="item.childItem" v-model="item.selectname" @change="selectClick($event,item)"  placeholder="请选择">
                    <el-option
                    v-for="(childItem,childIndex) in item.childItem"
                    :key="childIndex"
                    :label="childItem.name"
                    :value="childItem.id" style="width:188px;">
                    </el-option>
                </el-select>
            </div>
        </div>
        
    </div>
    <div class="junminFooter-main">
        <ul class="clearfix">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/junmin/junminLogo.png" alt="">
            <li @click="gostep(2)">关于我们</li>
            <li>合作伙伴</li>
            <li @click="gostep(3)">联系我们</li>
            <li>|</li>
            <li @click="gostep(1)">政府部门登录 ></li>
        </ul>
    </div>
    <saas-nsr-footer :bgColor="'#EAEAEA'" :textColor="'#363636'" :isContract="false"/>
 </div>
</template>

<script>
export default {
    name:'junminFooter',
    data () {
        return {
            frontendConfig:null,
            frontendConfig1:null,
            typeList:[
                {
                    id:1,
                    name:'青岛市政府网站 >',
                    url:'http://www.qingdao.gov.cn',
                },
                {
                    id:2,
                    name:'退役军人事务部网站 >',
                    url:'http://www.mva.gov.cn',
                },
                {
                    id:3,
                    selectname:1,
                    childItem:[
                        {
                            id:1,
                            name:'各省退役军人系统网站',
                        },
                        {
                            id:2,
                            name:'北京市退役军人事务局',
                            url:'http://tyjrswj.beijing.gov.cn/',
                        },
                        {
                            id:3,
                            name:'天津市退役军人事务局',
                            url:'http://tyjrswj.tj.gov.cn/',
                        },
                        {
                            id:4,
                            name:'河北省退役军人事务厅',
                            url:'http://tyjrswt.hebei.gov.cn/',
                        },
                        {
                            id:5,
                            name:'山西省退役军人事务厅',
                            url:'http://tyjrt.shanxi.gov.cn/',
                        },
                        {
                            id:6,
                            name:'内蒙古自治区退役军人事务厅',
                            url:'http://tyjrswt.nmg.gov.cn/',
                        },
                        {
                            id:7,
                            name:'辽宁省退役军人事务厅',
                            url:'http://tyjrsw.ln.gov.cn/',
                        },
                        {
                            id:8,
                            name:'黑龙江省退役军人事务厅',
                            url:'http://dva.hlj.gov.cn/',
                        },
                        {
                            id:9,
                            name:'上海市退役军人事务局',
                            url:'http://tyjr.sh.gov.cn/',
                        },
                        {
                            id:10,
                            name:'江苏省退役军人事务厅',
                            url:'http://tyjrswt.jiangsu.gov.cn/',
                        },
                        {
                            id:11,
                            name:'浙江省退役军人事务厅',
                            url:'http://tyjrswt.zj.gov.cn/',
                        },
                        {
                            id:12,
                            name:'安徽省退役军人事务厅',
                            url:'http://dva.ah.gov.cn/',
                        },
                        {
                            id:13,
                            name:'福建省退役军人事务厅',
                            url:'http://tyjrswt.fujian.gov.cn/',
                        },
                        {
                            id:14,
                            name:'江西省退役军人事务厅',
                            url:'http://tyjrswt.jiangxi.gov.cn/',
                        },
                        {
                            id:15,
                            name:'山东省退役军人事务厅',
                            url:'http://dva.shandong.gov.cn/',
                        },
                        {
                            id:16,
                            name:'湖北省退役军人事务厅',
                            url:'http://va.hubei.gov.cn/',
                        },
                        {
                            id:17,
                            name:'湖南省退役军人事务厅',
                            url:'http://tyjrswt.hunan.gov.cn/',
                        },
                        {
                            id:18,
                            name:'广东省退役军人事务厅',
                            url:'http://dva.gd.gov.cn/',
                        },
                        {
                            id:19,
                            name:'广西壮族自治区退役军人事务厅',
                            url:'http://gjw.gxzf.gov.cn/',
                        },
                        {
                            id:20,
                            name:'海南省退役军人事务厅',
                            url:'http://tyt.hainan.gov.cn/',
                        },
                        {
                            id:21,
                            name:'四川省退役军人事务厅',
                            url:'http://dva.sc.gov.cn/',
                        },
                        {
                            id:22,
                            name:'贵州省退役军人事务厅',
                            url:'http://tyjrswt.guizhou.gov.cn/',
                        },
                        {
                            id:23,
                            name:'云南省退役军人事务厅',
                            url:'http://tyjrsw.yn.gov.cn/',
                        },
                        {
                            id:24,
                            name:'西藏自治区退役军人事务厅',
                            url:'http://tyjr.xizang.gov.cn/',
                        },
                        {
                            id:25,
                            name:'甘肃省退役军人事务厅',
                            url:'http://tyjr.gansu.gov.cn/CL0001/index.html',
                        },
                        {
                            id:26,
                            name:'青海省退役军人事务厅',
                            url:'https://tyjrt.qinghai.gov.cn/',
                        },
                    ]
                },
                {
                    id:4,
                    selectname:1,
                    childItem:[
                        {
                            id:1,
                            name:'山东省各地市退役军人',
                        },
                        {
                            id:2,
                            name:'济南市退役军人事务局',
                            url:'http://bva.jinan.gov.cn/',
                        },
                        {
                            id:3,
                            name:'青岛市退役军人事务局',
                            url:'http://bva.qingdao.gov.cn/',
                        },
                        {
                            id:4,
                            name:'淄博市退役军人事务局',
                            url:'http://tyjrswj.zibo.gov.cn/',
                        },
                        {
                            id:5,
                            name:'枣庄市退役军人事务局',
                        },
                        {
                            id:6,
                            name:'东营市退役军人事务局',
                            url:'http://bva.dongying.gov.cn/',
                        },
                        {
                            id:7,
                            name:'烟台市退役军人事务局',
                        },
                        {
                            id:8,
                            name:'潍坊市退役军人事务局',
                            url:'',
                        },
                        {
                            id:9,
                            name:'济宁市退役军人事务局',
                            url:'http://tyjrswj.jining.gov.cn/',
                        },
                        {
                            id:10,
                            name:'泰安市退役军人事务局',
                            url:'http://tyjrj.taian.gov.cn/',
                        },
                        {
                            id:11,
                            name:'威海市退役军人事务局',
                        },
                        {
                            id:12,
                            name:'日照市退役军人事务局',
                        },
                        {
                            id:13,
                            name:'滨州市退役军人事务局',
                        },
                        {
                            id:14,
                            name:'德州市退役军人事务局',
                            url:'http://tyjr.dezhou.gov.cn/',
                        },
                        {
                            id:13,
                            name:'聊城市退役军人事务局',
                            url:'http://dva.liaocheng.gov.cn/',
                        },
                        {
                            id:14,
                            name:'临沂市退役军人事务局',
                            url:'http://tyjrswj.linyi.gov.cn/',
                        },
                    ]
                },
                {
                    id:5,
                    selectname:1,
                    childItem:[
                        {
                            id:1,
                            name:'教育培训联盟网站',
                        },
                        {
                            id:2,
                            name:'上海财经大学青岛财富管理研究院',
                            url:'http://qwmi.shufe.edu.cn',
                        },
                        {
                            id:3,
                            name:'青岛职业技术学院培训学院',
                            url:'http://www.qtc.edu.cn',
                        },
                        {
                            id:4,
                            name:'青岛建装学校',
                            url:'http://www.qdjzh.cn',
                        },
                        {
                            id:5,
                            name:'青岛德利丰职业培训学校',
                            url:'http://80999.net',
                        },
                        {
                            id:6,
                            name:'伟东云教育集团有限公司',
                            url:'http://www.wdecloud.com',
                        },
                        {
                            id:7,
                            name:'青岛二十一世纪人力资源合作与开发培训学院',
                            url:'http://www.qdzhenrong.com',
                        },
                        {
                            id:8,
                            name:'青岛军民融合学院',
                            url:'http://www.qdhdzj.com',
                        },
                    ]
                },
                {
                    id:6,
                    selectname:1,
                    childItem:[
                        {
                            id:1,
                            name:'创业孵化基地网站',
                        },
                        {
                            id:2,
                            name:'网站一'
                        },
                        {
                            id:3,
                            name:'网站二'
                        },
                        
                    ]
                }
            ]
        };
    },

 methods: {
    selectClick(id,item){
        if(!item.childItem){
            window.open(item.url);
        }else{
            item.childItem.forEach(val=>{
                if(val.id===id){
                    if(val.url){
                        window.open(val.url);
                    }else{
                        item.selectname=id;
                    }
                    
                }
            })
        }
    },
    gostep(type){
        if(type===1){
            
            const hostname = window.location.hostname
            if (hostname.indexOf('saas') !== -1) {
                const env = hostname.split('.')[2];
                window.open(`https://hrss-main.${env}.wdeduc.com`, 'newW');
            } else {
                window.open('https://hrss-main.wdeduc.com', 'newW');
            }
        }else if(type===2){
            this.$router.push({
                path: this.frontendConfig.value
            });
        }else if(type===3){
            this.$router.push({
                path: this.frontendConfig1.value
            });
        }
    },
 },

 mounted(){
    const frontendConfig = JSON.parse(localStorage.getItem('configurationArr'))
     frontendConfig && frontendConfig.filter(item => {
        if(item.key === 'about_us_config'){
            this.frontendConfig=item;
        }
        if(item.key === 'contact_us_config'){
            this.frontendConfig1 = item;
        }
    })[0]
 },
}

</script>
<style lang='stylus' scoped>
.junminFooter
    width 100%
    &-top
        background: #F5F8FF;
        padding 22px 0 21px 0;
        &-main
            width 1200px
            margin 0 auto
            padding-left 32px
            box-sizing box-sizing
            &-li
                float left
                &-name
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #1972FF;
                    line-height 45px
                    margin-right 35px
                    cursor pointer
                    .circular
                        display inline-block
                        width 13px;
                        height 13px;
                        background: rgba(25, 114, 255, 0.19);
                        margin-right 7px
                        border-radius 50%
                        span
                            display: block;
                            width: 9px;
                            height: 9px;
                            background: #1972FF;
                            border-radius 50%
                            margin: 0 auto;
                            margin-top 2px
                            
                .el-select
                    margin-right 16px      
                    width 188px
                    height 45px
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #4D4D4D;
                &:last-child
                    .el-select
                        margin-right 0px   
    &-main
        height 43px
        margin 44px 0
        ul
            width 1200px
            margin 0 auto
            img{
                width 427px
                height 43px
                float left
                margin 0 73px 0 120px
            }
            li
                float left
                line-height 43px
                font-size: 16px;
                font-weight: 400;
                color: #1972FF;
                margin-right 30px;
                cursor pointer
                &:nth-child(3),&:nth-child(5)
                    color: #4D4D4D;
                
            
    &-bottom
        height: 52px;
        background: rgba(219, 219, 219, 0.5026);
        line-height 52px
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4D4D4D;
        text-align center;
        img
            width 16px
            height 15px
            vertical-align: middle;
.el-select-dropdown
    width 188px

</style>