<template>
    <div class="shjdFooter">
        <div class="content">
            <div class="left">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shjd/logoBot.png" />
            </div>
            <div class="center">
                <div>主办单位：上海交大教育集团干部教育研究院</div>
                <div>承办单位：上海观乎教育科技有限公司</div>
                <div>邮编：200030</div>
                <div>地址：上海市徐汇区番禺路667号南洋大楼4楼</div>
                <div>备案号：沪ICP备2021015917号-1</div>
            </div>
            <div class="right">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shjd/goSee.png" />
                <div>扫码查看手机版</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'sdjtFooter',
    data(){
        return{

        }
    }
}
</script>
<style lang="stylus" scoped>
.shjdFooter{
    width 100%
    height: 260px;
    background: #333333;
    .content{
        width 1200px
        padding-top:10px;
        padding-bottom:10px;
        margin 0 auto;
        box-sizing border-box;
        height 100%;
        display:flex;
        align-items:center;
        .left{
            display inline-block
            width: 218px;
            height: 76px;
            margin-right:120px
            img{
                width 100%
                height 100%
            }
        }
        .center{
            display:inline-block;
            color:#C2C2C2;
            font-size: 16px;
            font-family: MicrosoftYaHei;
            line-height: 36px;
            margin-right:416px
        }   
        .right{
            display:inline-block;
            text-align:center;
            font-size: 16px;
            font-family: MicrosoftYaHei;
            color: #FFFFFF;
            line-height: 21px;
            img{
                margin-bottom:16px;
                width: 120px;
                height: 120px; 
            }
        } 
    }

}
</style>